import * as React from "react";
import { LineIcon } from "@common/icons";
import { Col, Row, Text } from "@gada-saas/web-ui";
import useStyles from "./styles";
import { InfoModal } from "@components/InfoModal";

export default function SalesInfoModal() {
  const classes = useStyles();

  return (
    <InfoModal
      title="Penjualan"
      content={
        <Col>
          <Text variant="h4" className={classes.infoPopOverSales}>
            Penjualan Kotor
          </Text>

          <Row className={classes.description}>
            <Col className={classes.lineIconContainer}>
              <LineIcon />
            </Col>

            <Text>total penjualan, baik yang lunas ataupun belum</Text>
          </Row>

          <Text variant="h4" className={classes.infoPopOverSales}>
            Penjualan Bersih
          </Text>

          <Row>
            <Col className={classes.lineIconContainer}>
              <LineIcon />
            </Col>

            <Text>penjualan kotor dikurangi diskon dan retur</Text>
          </Row>
        </Col>
      }
    />
  );
}
