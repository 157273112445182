import { Modal, Text } from "@gada-saas/web-ui";
import parse, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";
import * as React from "react";
import useStyles from "./styles";
import { TnCModalProps } from "./types";

const TnCModal: React.VFC<TnCModalProps> = ({
  open,
  content,
  isLoading,
  onPressAgree,
  onPressDisagree,
}) => {
  const classes = useStyles();

  const parserConfig: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.name === "p") {
        return <Text>{domToReact(domNode.children, parserConfig)}</Text>;
      }

      if (domNode instanceof Element && domNode.name === "a") {
        return (
          <a
            className={classes.anchor}
            onClick={() => {
              window.open(domNode.attribs.href, "_blank");
            }}
          >
            {domToReact(domNode.children, parserConfig)}
          </a>
        );
      }
    },
  };

  return (
    <Modal
      open={open}
      title="Perubahan Syarat & Ketentuan GudangAda Solusi"
      content={parse(content, parserConfig)}
      primaryButtonText="Ya, Saya Setuju"
      primaryButtonProps={{ isLoading }}
      onClickPrimaryButton={onPressAgree}
      secondaryButtonText="Tidak Setuju"
      secondaryButtonProps={{ isLoading }}
      onClickSecondaryButton={onPressDisagree}
    />
  );
};

export default TnCModal;
