import * as React from "react";
import { useRouteHistory } from "@common/context/RouteHistoryProvider/RouteHistoryProvider";
import {
  useActiveStoreState,
  useGetAllTutorialsQuery,
  useGetStoreOperatingTimeQuery,
  UserStoreRole,
  useStoresActions,
} from "@gada-saas/web-core";
import { isValidOperationalTime } from "../utils";
import { PAGES } from "@common/constants";
import { FeaturesFlagContext } from "@common/context/FeaturesFlagProvider/FeaturesFlagProvider";

export function usePopupStoreSpecialtyTypeModal(): void {
  const { history } = useRouteHistory();
  const activeStore = useActiveStoreState();
  const storesActions = useStoresActions();

  const { homePage } = React.useContext(FeaturesFlagContext);

  const { data: tutorialsList } = useGetAllTutorialsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: getStoreOperatingTimeData } = useGetStoreOperatingTimeQuery(
    activeStore.storeId || "",
    {
      skip: !activeStore.storeId,
    }
  );

  const shouldShowStoreSpecialtyTypeModalForExistingUser = React.useMemo(() => {
    if (
      !homePage.storeSpecialtyPopup ||
      !tutorialsList ||
      !getStoreOperatingTimeData
    )
      return false;

    // Onboarding user will be handled with Coach Mark Flow,
    // in component AppTutorialStep3
    const userHasNotBeenOnboarded =
      !tutorialsList.data.progressList.FIRST_TUTORIAL;
    if (userHasNotBeenOnboarded) return false;

    const prevRoute: string | undefined = history[history.length - 2];
    if (!prevRoute) return false;

    const queryStringStartIndex = prevRoute.indexOf("?");
    const path =
      queryStringStartIndex >= 0
        ? prevRoute.slice(0, queryStringStartIndex)
        : prevRoute;
    const isPrevRouteLogin = path === PAGES.LOGIN;

    if (!isPrevRouteLogin) return false;

    const isUserStoreRoleOwner = activeStore.storeRoleList[0]
      ? activeStore.storeRoleList[0].name === UserStoreRole.OWNER
      : false;
    if (!isUserStoreRoleOwner) return false;

    const userHasSetStoreSpecialtyType = activeStore.hasSpecialtyType;
    if (userHasSetStoreSpecialtyType) return false;

    const currentDate = new Date();
    const todayDayOfWeek = currentDate.getDay();

    const operatingTime = getStoreOperatingTimeData.data.operatingTimes.find(
      (v) => v.dayOfWeek === todayDayOfWeek
    );
    if (
      !isValidOperationalTime(
        operatingTime?.startTime.split(":")[0],
        operatingTime?.endTime.split(":")[0]
      )
    )
      return false;

    return true;
  }, [
    homePage.storeSpecialtyPopup,
    activeStore.hasSpecialtyType,
    activeStore.storeRoleList,
    getStoreOperatingTimeData,
    history,
    tutorialsList,
  ]);

  React.useEffect(() => {
    if (shouldShowStoreSpecialtyTypeModalForExistingUser) {
      storesActions.setStoreSpecialtyModalState({
        open: true,
        origin: "home",
      });
    }
  }, [shouldShowStoreSpecialtyTypeModalForExistingUser, storesActions]);
}

export default usePopupStoreSpecialtyTypeModal;
