import { InputLabel } from "@material-ui/core";
import * as React from "react";
import Text from "../../Typography/Typography";
import { LabelProps } from "./types";

export const Label: React.VFC<LabelProps> = ({
  id,
  label,
  ink,
  className,
}: LabelProps) => {
  return (
    <InputLabel htmlFor={id} className={className}>
      <Text variant="h5" ink={ink || "neutralPrimary"}>
        {label}
      </Text>
    </InputLabel>
  );
};

export default Label;
