import { gadaSaasApi } from "../base-rtkq-api";
import { Endpoints } from "../common/api";
import {
  GetNotificationBannerRequest,
  GetNotificationBannerResponse,
} from "./models";

const NotificationsApi = gadaSaasApi.injectEndpoints({
  endpoints: (build) => ({
    getNotificationBanner: build.query<
      GetNotificationBannerResponse,
      GetNotificationBannerRequest
    >({
      query: () => ({
        method: "GET",
        url: Endpoints.NOTIFICATION_BANNER,
      }),
    }),
  }),
});

export const { useGetNotificationBannerQuery } = NotificationsApi;

export default NotificationsApi;
