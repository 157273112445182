import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppTheme } from "@gada-saas/web-ui/theme";
import { pxToRem } from "@gada-saas/web-ui/utils";

export const useStyles = makeStyles<AppTheme>((theme) => ({
  icon: {
    cursor: "pointer",
    marginLeft: pxToRem(10),
    color: theme.palette.interface.neutral.secondary,
  },
  popoverInfoDescription: {
    marginBottom: pxToRem(30),
  },
  paperPopover: {
    padding: theme.spacing(6),
    width: pxToRem(560),
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  link: {
    textDecorationLine: "none",
    color: theme.palette.interface.teal.primary,
    fontWeight: "bold",
  },
  productVariantImg: {
    border: "1px solid " + theme.palette.interface.neutral.light,
    width: pxToRem(56),
    height: pxToRem(56),
    maxWidth: pxToRem(56),
    maxHeight: pxToRem(56),
    overflow: "hidden",
  },
  card: { padding: `${pxToRem(4)} 0` },
  tableContainer: {
    maxHeight: pxToRem(480),
    overflowY: "auto",
  },
  tableHeadingCell: {
    padding: `${pxToRem(24)} ${pxToRem(16)} ${pxToRem(16)}`,
  },
}));
