import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  defaultIcon: {
    width: pxToRem(16),
    height: pxToRem(16),
    color: theme.palette.interface.neutral.secondary,
  },
  outlinedIcon: {
    width: pxToRem(24),
    height: pxToRem(24),
    color: theme.palette.interface.neutral.primary,
  },
}));

export default useStyles;
