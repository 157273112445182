export function isValidOperationalTime(startHours = "08", endHours = "17") {
  const currentDate = new Date();
  const currentHours = currentDate.getHours();
  let currStart = startHours;
  const set = new Set<number>();

  while (currStart !== endHours) {
    let n = Number(currStart);
    set.add(n);
    n += 1;
    if (n === 24) {
      n = 0;
    }
    currStart = n < 10 ? "0" + String(n) : String(n);
  }

  return !set.has(currentHours);
}
