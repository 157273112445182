import useStyles from "./styles";
import { IconButton } from "@material-ui/core";
import { Info, InfoOutlined } from "@material-ui/icons";
import * as React from "react";
import { useModalState } from "@common/hooks";
import { Col, Modal } from "@gada-saas/web-ui";
import { InfoModalProps } from "./types";

const InfoModal: React.VFC<InfoModalProps> = ({
  title,
  content,
  iconType = "default",
  iconClassName,
}) => {
  const classes = useStyles();

  const [openModal, toggleModal] = useModalState();

  return (
    <Col>
      <IconButton className={iconClassName} onClick={toggleModal}>
        {iconType === "default" ? (
          <Info className={classes.defaultIcon} />
        ) : (
          <InfoOutlined className={classes.outlinedIcon} />
        )}
      </IconButton>

      <Modal
        open={openModal}
        title={title}
        content={content}
        withCloseButton
        onClickCloseButton={toggleModal}
      />
    </Col>
  );
};

export default InfoModal;
