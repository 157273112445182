import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import Label from "../Label";
import useClasses from "./styles";
import { InputModeOptions, TextFieldProps } from "./types";

const TextField: React.VFC<TextFieldProps> = ({
  id,
  name,
  label,
  labelInk,
  value,
  type = "text",
  inputMode = InputModeOptions.TEXT,
  error,
  errorText,
  helperText,
  disabled,
  startIcon,
  endIcon,
  className,
  noLabelTopMargin = false,
  handleChange,
  handleBlur,
  inputRef,
  multiline = false,
  ...props
}: TextFieldProps) => {
  const classes = useClasses({});
  const showHelperText =
    helperText !== undefined &&
    helperText !== errorText &&
    typeof helperText === "string"
      ? helperText?.trim()?.length > 0
      : true;
  const isError = Boolean(errorText) || error;

  return (
    <Box
      className={clsx(
        classes.fieldWrapper,
        label && !noLabelTopMargin && classes.marginTop,
        className
      )}
    >
      {label ? <Label id={id} label={label} ink={labelInk} /> : null}
      <FormControl error={error || Boolean(errorText)} disabled={disabled}>
        <OutlinedInput
          className={classes.input}
          id={id}
          type={type}
          inputProps={{
            name,
            value,
            inputMode,
            onChange: handleChange,
            onBlur: handleBlur,
            ref: inputRef,
          }}
          startAdornment={
            startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            )
          }
          endAdornment={
            endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
          }
          rows={3}
          multiline={multiline}
          error={isError}
          {...props}
        />
        {showHelperText && typeof helperText === "string" ? (
          <FormHelperText id={id} error={false}>
            {helperText && helperText.trim()}
          </FormHelperText>
        ) : (
          helperText
        )}
        {errorText?.trim() && (
          <FormHelperText id={id}>{errorText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default TextField;
