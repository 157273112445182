import { PERMISSION_LIST } from "@common/constants";
import { withAuthProtection } from "@common/hocs/withAuth";
import { NorthEastIcon, SouthWestIcon } from "@common/icons";
import GridLayout from "@common/layouts/GridLayout";
import {
  AppStateType,
  GetStoreSpecialtyAPIResponse,
  SalesProductStateSortBy,
  SortType,
  useGetListInventoryQuery,
  useGetSalesPerCategoryReportQuery,
  useGetSalesPerProductQuery,
  useGetSalesReportQuery,
  useGetUserInfoQuery,
  useInventoryListActions,
  useStoresActions,
  useUserMenusActions,
  useUserMenusState,
} from "@gada-saas/web-core";
import { DurationTypes, Channels } from "@gada-saas/web-core/report/types";
import {
  AppTheme,
  Button,
  currencyFormatter,
  pxToRem,
  Table,
  Text,
} from "@gada-saas/web-ui";
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { getQueryParams } from "@utils/urls";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { Chip, Container, useStyles } from "./styles";
import { PAGES } from "@common/constants";
import TopSellingItemInfoModal from "./components/TopSellingItemInfoModal/TopSellingItemInfoModal";
import TopSellingCategoryInfoModal from "./components/TopSellingCategoryInfoModal/TopSellingCategoryInfoModal";
import SalesInfoModal from "./components/SalesInfoModal/SalesInfoModal";
import { useDispatch } from "react-redux";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { iInventoryListItem } from "@gada-saas/web-core/inventory";
import { TnCUpdate } from "./components/TnCUpdate";
import { NotificationBanner } from "./components/NotificationBanner";
import { MostPurchasedInventorySection } from "./components/MostPurchasedInventorySection";
import { SellInMPRecommendationSection } from "./components/SellInMPRecommendationSection";
import StoreSpecialtyTypeModal from "@user/settings/components/StoreSpecialtyTypeModal";
import UserApi from "@gada-saas/web-core/user/hooks";
import { usePopupStoreSpecialtyTypeModal } from "./hooks";
import CoachMartListener from "@components/CoachMartListener/CoachMartListener";

const HomePageContent: React.VFC = () => {
  const analytics = useSegmentAnalytics();
  const classes = useStyles();
  // TODO change with accurate session
  const router = useRouter();
  const { palette } = useTheme<AppTheme>();
  const { unauthorized } = getQueryParams(router.asPath);
  const UserMenuActions = useUserMenusActions();
  const { activeStore } = useUserMenusState();
  const dispatch = useDispatch();

  const InventoryListActions = useInventoryListActions();

  const storeId = activeStore.storeId;
  const sharedParam: AppStateType["globalReport"] = {
    durationEnd: null,
    durationStart: null,
    durationType: DurationTypes.DAILY,
    channel: Channels.SAAS,
  };

  const userInfo = useGetUserInfoQuery();
  const { data: salesReportData } = useGetSalesReportQuery(
    {
      ...sharedParam,
      storeId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );
  const { data: salesPerCategoryData } = useGetSalesPerCategoryReportQuery(
    {
      ...sharedParam,
      sortBy: "quantity_in_smallest_uom_aggregate",
      sortType: SortType.DESCENDING,
      pageSize: 1,
      page: 1,
      storeId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );
  const { data: salesPerProductData } = useGetSalesPerProductQuery(
    {
      globalReportParams: sharedParam,
      listParams: {
        productVariantName: null,
        uomId: null,
        sortBy: SalesProductStateSortBy.QUANTITY_IN_SMALLEST_UOM_AGGREGATE,
        sortType: SortType.DESCENDING,
        pageSize: 1,
        page: 1,
      },
      storeId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );
  const { data: inventoryListData } = useGetListInventoryQuery(
    {
      storeId,
      pageSize: 5,
      page: 1,
      barcode: null,
      keyword: null,
      sortBy: "LOWEST_STOCK",
      sortType: SortType.ASCENDING,
      principalIds: [],
      uomId: [],
      isSoldOnline: null,
      isConsignor: null,
      isLowStock: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !storeId,
    }
  );

  const storesActions = useStoresActions();

  usePopupStoreSpecialtyTypeModal();

  /**
   * Effects
   */
  // Display no Access if not authorized
  React.useEffect(() => {
    if (unauthorized) {
      UserMenuActions.setShowNoAccessPopup(true);
    }
  }, [unauthorized, UserMenuActions]);

  /**
   * Handlers
   */

  const goToReportsPage = React.useCallback(() => {
    analytics.track("All Report Homepage Clicked");
    router.push(PAGES.REPORT);
  }, [analytics, router]);

  const goToSales = React.useCallback(() => {
    analytics.track("Nett Sales Report Homepage Clicked");
    router.push(PAGES.SALES);
  }, [analytics, router]);

  const goToCustomerDebtAllTab = React.useCallback(() => {
    analytics.track("Transaction Sales Report Homepage Clicked");
    router.push(PAGES.CUSTOMER_DEBT_ALL_TAB);
  }, [analytics, router]);

  const goToInventoryList = React.useCallback(() => {
    analytics.track("Low Stock All Clicked");

    InventoryListActions.setIsLowStock(true);

    router.push(PAGES.INVENTORY);
  }, [InventoryListActions, analytics, router]);

  const handleLowStockProductClick = React.useCallback(
    (inventory: iInventoryListItem) => {
      analytics.track("Low Stock Product Clicked", {
        product_id: inventory.productVariantId,
        product_name: inventory.productVariantName,
        stock: inventory.cumulativeStock.quantity,
      });

      router.push({
        pathname: PAGES.EDIT_INVENTORY_CURATED_PRODUCT,
        query: { productVariantId: inventory.productVariantId },
      });
    },
    [analytics, router]
  );

  const handleClickGoToTopItems = React.useCallback(() => {
    analytics.track("Top Selling Item Report Homepage Clicked");
    router.push(PAGES.TOP_SELLING_ITEMS);
  }, [analytics, router]);

  const handleClickGoToTopCategories = React.useCallback(() => {
    analytics.track("Top Selling Category Report Homepage Clicked");
    router.push(PAGES.TOP_SELLING_CATEGORY);
  }, [analytics, router]);

  /**
   * Rendering & Format Related
   */

  const diffNetAmountPercentage = React.useMemo(() => {
    if (!salesReportData) {
      return 0;
    }

    return Number(salesReportData.previousPeriodSale.netAmountAggregate) === 0
      ? 100
      : ((Number(salesReportData.currentPeriodSale.netAmountAggregate) -
          Number(salesReportData.previousPeriodSale.netAmountAggregate)) /
          Number(salesReportData.previousPeriodSale.netAmountAggregate)) *
          100;
  }, [salesReportData]);

  const diffTransactionCount = React.useMemo(() => {
    if (!salesReportData) {
      return 0;
    }

    return salesReportData.previousPeriodSale.transactionCount === 0
      ? 100
      : ((salesReportData.currentPeriodSale.transactionCount -
          salesReportData.previousPeriodSale.transactionCount) /
          salesReportData.previousPeriodSale.transactionCount) *
          100;
  }, [salesReportData]);

  const isDiffNetAmountPositive = diffNetAmountPercentage >= 0;
  const isDiffTransactionCountPositive = diffTransactionCount >= 0;

  const onSuccessSubmitStoreSpecialtySetting = React.useCallback(
    (response: GetStoreSpecialtyAPIResponse) => {
      if (response.data) {
        // TODO: remove this manual invalidation tags
        // and put all of API hooks in one api module
        dispatch(UserApi.util.invalidateTags(["User"]));
        storesActions.resetStoreSpecialtyModalState();
      }
    },
    [dispatch, storesActions]
  );

  return (
    <GridLayout mainBgColor="neutralLight">
      <Container maxWidth={"md"}>
        <Box marginBottom={pxToRem(48)} marginTop={pxToRem(80)}>
          <Text id="text_welcome" variant="h2" className={classes.welcomeText}>
            Selamat datang,{" "}
            <span className={classes.userName}>
              {userInfo.data?.data.userInfo.name}
            </span>
          </Text>
          <Text>Review aktivitas toko Anda hari ini</Text>
        </Box>

        <NotificationBanner />

        <Box marginBottom={pxToRem(48)}>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom={pxToRem(16)}
          >
            <Text variant="h3">Penjualan Hari Ini</Text>
            <Button variant="text" onClick={goToReportsPage}>
              <Text variant="button" ink="tealPrimary">
                Lihat Semua Laporan
              </Text>
            </Button>
          </Box>
          <Card style={{ marginBottom: "16px" }}>
            <Box
              paddingX={pxToRem(24)}
              paddingY={pxToRem(16)}
              display="flex"
              alignItems="center"
            >
              <Text variant="h4">Penjualan</Text>
              <SalesInfoModal />
            </Box>
            <Divider />
            <Grid container>
              <Grid item xs={6}>
                <Box padding={pxToRem(24)}>
                  <Text variant="body2" ink="neutralPrimary">
                    Total Penjualan Bersih
                  </Text>
                  <Box display="flex" alignItems="center">
                    <Text variant="h2">
                      {currencyFormatter(
                        salesReportData?.currentPeriodSale.netAmountAggregate
                      )}
                    </Text>
                    <IconButton style={{ padding: 0 }} onClick={goToSales}>
                      <ChevronRight
                        className={classes.pointer}
                        color="primary"
                      />
                    </IconButton>
                  </Box>
                  <Box display="flex">
                    <Text
                      variant="h5"
                      ink={
                        isDiffNetAmountPositive ? "tealPrimary" : "redPrimary"
                      }
                    >
                      {isDiffNetAmountPositive ? "+" : "-"}&nbsp;
                      {Math.abs(diffNetAmountPercentage).toFixed(1)}%&nbsp;
                    </Text>
                    <Text variant="body2" ink="neutralPrimary">
                      dari kemarin
                    </Text>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box padding={pxToRem(24)}>
                  <Text variant="body2" ink="neutralPrimary">
                    Transaksi
                  </Text>
                  <Box display="flex" alignItems="center">
                    <Text variant="h2">
                      {salesReportData?.currentPeriodSale.transactionCount ?? 0}
                    </Text>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={goToCustomerDebtAllTab}
                    >
                      <ChevronRight
                        className={classes.pointer}
                        color="primary"
                      />
                    </IconButton>
                  </Box>
                  <Box display="flex">
                    <Text
                      variant="h5"
                      ink={
                        isDiffTransactionCountPositive
                          ? "tealPrimary"
                          : "redPrimary"
                      }
                    >
                      {isDiffTransactionCountPositive ? "+" : "-"}&nbsp;
                      {Math.abs(diffTransactionCount).toFixed(1)}%&nbsp;
                    </Text>
                    <Text variant="body2" ink="neutralPrimary">
                      dari kemarin
                    </Text>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Card>
                <Box
                  paddingX={pxToRem(24)}
                  paddingY={pxToRem(16)}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Text variant="h4">Barang Paling Laku</Text>
                    <TopSellingItemInfoModal />
                  </Box>
                  <ChevronRight
                    className={classes.pointer}
                    color="primary"
                    onClick={handleClickGoToTopItems}
                  />
                </Box>
                <Divider />
                <Box padding={pxToRem(24)}>
                  {(salesPerProductData?.data ?? []).length > 0 ? (
                    <>
                      <Text variant="h3">
                        {salesPerProductData?.data[0].productVariantName}
                      </Text>
                      <Text variant="body2" ink="neutralPrimary">
                        Terjual{" "}
                        {
                          salesPerProductData?.data[0]
                            .quantityInSmallestUomAggregate
                        }{" "}
                        {salesPerProductData?.data[0].uomName}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text>Belum ada transaksi penjualan</Text>
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Box
                  paddingX={pxToRem(24)}
                  paddingY={pxToRem(16)}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Text variant="h4">Kategori Paling Laku</Text>
                    <TopSellingCategoryInfoModal />
                  </Box>
                  <ChevronRight
                    className={classes.pointer}
                    color="primary"
                    onClick={handleClickGoToTopCategories}
                  />
                </Box>
                <Divider />
                <Box padding={pxToRem(24)}>
                  {(salesPerCategoryData?.data ?? []).length > 0 ? (
                    <>
                      <Text variant="h3">
                        {salesPerCategoryData?.data[0].categoryName ?? ""}
                      </Text>
                      <Text variant="body2" ink="neutralPrimary">
                        Terjual{" "}
                        {salesPerCategoryData?.data[0]
                          .quantityInSmallestUomAggregate ?? 0}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text>Belum ada transaksi penjualan</Text>
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box mb={pxToRem(48)}>
          <Box
            display="flex"
            marginBottom={pxToRem(16)}
            justifyContent="space-between"
          >
            <Text variant="h3">Jatuh Tempo Hari ini</Text>
          </Box>
          <Card>
            <Box paddingX={pxToRem(24)} paddingY={pxToRem(16)}>
              <Text variant="h4">Utang Piutang</Text>
            </Box>
            <Divider />
            <Box display="flex">
              <Box
                padding={pxToRem(24)}
                display="flex"
                alignItems="center"
                flex={1}
              >
                <Box marginRight={pxToRem(12)}>
                  <NorthEastIcon fill={palette.interface.red.primary} />
                </Box>
                <Box>
                  <Text variant="body2" ink="neutralPrimary">
                    Utang Konsinyasi ke Supplier
                  </Text>
                  <Box alignItems="center" display="flex">
                    <Text variant="h2">
                      <b>Rp 0</b>
                    </Text>
                    <Link href={PAGES.CONSIGNMENT_DEBT} passHref>
                      <ChevronRight
                        className={classes.pointer}
                        color={"primary"}
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                padding={pxToRem(24)}
                display="flex"
                alignItems="center"
                flex={1}
              >
                <Box marginRight={pxToRem(12)}>
                  <SouthWestIcon fill={palette.interface.teal.primary} />
                </Box>
                <Box>
                  <Text variant="body2" ink="neutralPrimary">
                    Piutang Pelanggan
                  </Text>
                  <Box alignItems="center" display="flex">
                    <Text variant="h2">
                      <b>Rp 0</b>
                    </Text>
                    <Link href={PAGES.CUSTOMER_DEBT} passHref>
                      <ChevronRight
                        className={classes.pointer}
                        color={"primary"}
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>

        {inventoryListData && inventoryListData.total > 0 && (
          <Box marginBottom={pxToRem(48)}>
            <Box
              display="flex"
              marginBottom={pxToRem(16)}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="h3">Barang dengan Stok Rendah</Text>
              <Button variant="text" onClick={goToInventoryList}>
                <Text variant="button" ink="tealPrimary">
                  Lihat semua
                </Text>
              </Button>
            </Box>

            <Card>
              <TableContainer>
                <Table
                  tableHeadingCells={[
                    {
                      id: "productVariantName",
                      content: "Nama Barang",
                    },
                    {
                      id: "stock",
                      content: "Stock",
                    },
                    {
                      id: "action",
                      content: "",
                    },
                  ]}
                  renderTableData={() => {
                    return inventoryListData?.data.map((d) => (
                      <TableRow key={d.productVariantId}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Box marginRight={"16px"}>
                              <img
                                src={d.productVariantPhoto}
                                width={56}
                                height={56}
                                alt={d.productVariantName}
                              />
                            </Box>
                            <Text>{d.productVariantName}</Text>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={`${d.cumulativeStock.quantity} ${d.cumulativeStock.name}`}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => handleLowStockProductClick(d)}
                          >
                            <ChevronRight color={"primary"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ));
                  }}
                />
              </TableContainer>
            </Card>
          </Box>
        )}

        {/* Most Purchased Inventory Section */}
        <MostPurchasedInventorySection />

        {/* Sell in MP Recommendation Section */}
        <SellInMPRecommendationSection />

        {/* Onboarding Coach Mark */}
        <CoachMartListener page="home" />

        <TnCUpdate />
      </Container>
      <StoreSpecialtyTypeModal
        storeId={activeStore.storeId}
        onSuccess={onSuccessSubmitStoreSpecialtySetting}
      />
    </GridLayout>
  );
};

export default withAuthProtection({
  pageName: "Home Page",
  pagePermissions: [PERMISSION_LIST.COMMON],
})(HomePageContent);
