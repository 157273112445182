import { makeStyles } from "@material-ui/core";
import { AppTheme, pxToRem } from "@gada-saas/web-ui";

const useStyles = makeStyles((theme: AppTheme) => ({
  card: {
    marginBottom: pxToRem(16),
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  loadingContainer: {
    padding: pxToRem(24),
  },
  headingContainer: {
    padding: `${pxToRem(16)} ${pxToRem(24)}`,
  },
  infoPopOverSales: {
    marginBottom: pxToRem(8),
  },
  description: {
    marginBottom: pxToRem(40),
  },
  lineIconContainer: {
    marginRight: pxToRem(12),
  },
  span: {
    fontWeight: 700,
    color: theme.palette.interface.teal.primary,
  },
  infoContainer: {
    padding: pxToRem(24),
  },
  totalSales: {
    marginBottom: pxToRem(4),
  },
  sales: {
    marginBottom: pxToRem(4),
  },
  rightIcon: {
    color: theme.palette.interface.teal.primary,
  },
  day: {
    marginLeft: pxToRem(8),
  },
  divider: {
    height: pxToRem(4),
  },
}));

export default useStyles;
