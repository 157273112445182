import { Order, theme } from "@gada-saas/web-ui";
import * as React from "react";
import { TableSortIconProps } from "./types";

const TableSortIcon: React.VFC<TableSortIconProps> = ({ sort }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        d="m15 12-5 5-5-5h10z"
        fill={
          sort === Order.Desc
            ? theme.palette.interface.teal.primary
            : theme.palette.interface.neutral.secondary
        }
      />
      <path
        d="m5 8 5-5 5 5h-10z"
        fill={
          sort === Order.Asc
            ? theme.palette.interface.teal.primary
            : theme.palette.interface.neutral.secondary
        }
      />
    </svg>
  );
};

export default TableSortIcon;
