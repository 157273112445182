import { makeStyles } from "@material-ui/core";
import {
  PaginationRenderItemParams,
  PaginationItem,
  Pagination,
  PaginationProps,
} from "@material-ui/lab";
import * as React from "react";
import { AppTheme, pxToRem } from "../..";

/** TABLE PAGINATION */
const useTablePaginationStyles = makeStyles<AppTheme>((theme) => ({
  pagination: {
    // marginTop: pxToRem(32),
    float: "right",
  },
  paginationItemColor: {
    color: theme.palette.interface.neutral.primary,
  },
  paginationItemPage: {
    borderRadius: pxToRem(4),
    backgroundColor: theme.palette.interface.white,
    color: theme.palette.interface.neutral.primary,
    border: `1px solid ${theme.palette.interface.neutral.light}`,
    "&.Mui-selected": {
      borderColor: theme.palette.interface.teal.primary,
      backgroundColor: theme.palette.interface.teal.primary,
      color: theme.palette.interface.white,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.interface.teal.dark,
      borderColor: theme.palette.interface.teal.dark,
      color: theme.palette.interface.white,
    },
  },
}));

interface TablePaginationProps extends PaginationProps {
  page: number;
  count: number;
  onPageChange: (newPage: number) => void;
  previousPageItemId?: string;
  nextPageItemId?: string;
  /** It will be generated as `{pageItemPrefixId}_{pageNo}` */
  pageItemPrefixId?: string;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  count,
  page,
  onPageChange,
  previousPageItemId,
  nextPageItemId,
  pageItemPrefixId,
  ...restProps
}) => {
  const classes = useTablePaginationStyles();

  const renderPaginationItem = (item: PaginationRenderItemParams) => {
    const { type, page } = item;
    if (type === "page") {
      return (
        <PaginationItem
          {...item}
          className={classes.paginationItemPage}
          {...(pageItemPrefixId && {
            id: `${pageItemPrefixId}_${page}`,
          })}
        />
      );
    }
    return (
      <PaginationItem
        {...(type === "previous" &&
          previousPageItemId && {
            id: `${previousPageItemId}`,
          })}
        {...(type === "next" &&
          nextPageItemId && {
            id: `${nextPageItemId}`,
          })}
        {...item}
        classes={{ root: classes.paginationItemColor }}
      />
    );
  };

  return (
    <Pagination
      page={page}
      onChange={(_: React.ChangeEvent<unknown>, newPage: number) =>
        onPageChange(newPage)
      }
      className={classes.pagination}
      count={count}
      renderItem={renderPaginationItem}
      siblingCount={1}
      {...restProps}
    />
  );
};

export default TablePagination;
