import { OutlinedInputProps } from "@material-ui/core";
import React, { ReactNode } from "react";
import { TextProps } from "../../Typography/types";

export enum InputModeOptions {
  TEXT = "text",
  NONE = "none",
  TEL = "tel",
  URL = "url",
  EMAIL = "email",
  NUMERIC = "numeric",
  DECIMAL = "decimal",
  SEARCH = "search",
}

export interface TextFieldProps extends OutlinedInputProps {
  id: string;
  label?: string;
  labelInk?: TextProps["ink"];
  multiline?: boolean;
  errorText?: string | null;
  helperText?: React.ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  type?: string;
  inputMode?: InputModeOptions;
  noLabelTopMargin?: boolean;
  handleChange?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export interface BorderLessStyleParam {
  isError?: boolean;
}
