import { Modal } from "@gada-saas/web-ui";
import { useLogoutUser } from "@miscellaneous/navigation/components/UserSettingsPopover/useLogoutUser";
import * as React from "react";
import { TnCDisagreeModalProps } from "./types";

const TnCDisagreeModal: React.VFC<TnCDisagreeModalProps> = ({
  open,
  onReturnClick,
}) => {
  const logoutUser = useLogoutUser();

  const handleLogout = React.useCallback(() => {
    logoutUser();
  }, [logoutUser]);

  return (
    <Modal
      open={open}
      title="Konfirmasi Tidak Setuju"
      content="Jika tidak setuju, Anda tidak lagi bisa menggunakan layanan produk kami. Untuk lebih yakin, Anda juga bisa kembali dan baca ulang syarat dan ketentuan terbaru."
      primaryButtonText="Saya Tidak Setuju"
      onClickPrimaryButton={handleLogout}
      secondaryButtonText="Kembali"
      onClickSecondaryButton={onReturnClick}
    />
  );
};

export default TnCDisagreeModal;
