import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { createStyles, makeStyles, withStyles } from "@material-ui/core";
import { InputBase } from "@material-ui/core";
import { BorderLessStyleParam } from "./types";

const useClasses = makeStyles<AppTheme, BorderLessStyleParam>(
  ({ palette }) => ({
    fieldWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    marginTop: {
      marginTop: pxToRem(8),
    },
    input: {
      backgroundColor: palette.interface.white,
      color: ({ isError }) =>
        isError
          ? palette.interface.red.primary
          : palette.interface.neutral.dark,
      "& > input::placeholder": {
        opacity: ({ isError }) => (isError ? 1 : 0.4),
      },
    },
  })
);

export const UnstyledInput = withStyles(() =>
  createStyles({
    root: {
      "& > input": {
        padding: `${pxToRem(14)} ${pxToRem(16)}`,
      },
      "& > textarea": {
        padding: `${pxToRem(14)} ${pxToRem(16)}`,
      },
    },
  })
)(InputBase);

export default useClasses;
