import { AppTheme } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  anchor: {
    fontWeight: 700,
    color: theme.palette.interface.teal.primary,
    cursor: "pointer",
  },
}));

export default useStyles;
