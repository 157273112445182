import { useAsyncAction } from "@common/hooks";
import {
  AgreeTnCResponse,
  useAgreeTncMutation,
  useGetTnCAgreementQuery,
} from "@gada-saas/web-core";
import * as React from "react";
import { TnCDisagreeModal } from "../TnCDisagreeModal";
import { TnCModal } from "../TnCModal";

const TnCUpdate: React.VFC = () => {
  const [openTnc, setOpenTnc] = React.useState<boolean>(false);
  const [openTncDisagree, setOpenTncDisagree] = React.useState<boolean>(false);

  const { data } = useGetTnCAgreementQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [agreeTnc, { isLoading }] = useAsyncAction<AgreeTnCResponse>(
    useAgreeTncMutation()[0]
  );

  React.useEffect(() => {
    if (data?.data.user.isAgreeTnc === false) {
      setOpenTnc(true);
    }
  }, [data?.data.user.isAgreeTnc]);

  const handleOnPressAgree = React.useCallback(async () => {
    const res = await agreeTnc();
    if (res.success?.data.user.isAgreeTnc) {
      setOpenTnc(false);
    }
  }, [agreeTnc]);

  const handleOnPressDisagree = () => {
    setOpenTnc(false);
    setOpenTncDisagree(true);
  };

  const handleReturnClick = React.useCallback(() => {
    setOpenTncDisagree(false);
    setOpenTnc(true);
  }, []);

  return (
    <>
      <TnCModal
        open={openTnc}
        content={data?.data.content ?? ""}
        isLoading={isLoading}
        onPressAgree={handleOnPressAgree}
        onPressDisagree={handleOnPressDisagree}
      />

      <TnCDisagreeModal
        open={openTncDisagree}
        onReturnClick={handleReturnClick}
      />
    </>
  );
};

export default TnCUpdate;
