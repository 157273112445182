import * as React from "react";
import { Col, Text } from "@gada-saas/web-ui";
import useStyles from "./styles";
import { InfoModal } from "@components/InfoModal";

export default function TopSellingCategoryInfoModal() {
  const classes = useStyles();

  return (
    <InfoModal
      title="Kategori Paling Laku"
      content={
        <Col>
          <Text className={classes.description1}>
            Dihitung dari jumlah barang yang terjual per kategori, dalam unit
            terkecil penjualan.
          </Text>

          <Text className={classes.description1}>
            <span className={classes.bold}>Contoh</span>: Anda menjual Indomie
            Ayam Bawang (kategori Makanan) dalam unit Karton dan Pieces. Hari
            ini terjual 4 Karton dan 3 Pieces, dengan 1 Karton = 20 Pieces.
          </Text>

          <Text>Total barang terjual untuk kategori Makanan</Text>

          <Text>= (4 x 20) Pieces + 3 Pieces = 83 Pieces</Text>
        </Col>
      }
    />
  );
}
