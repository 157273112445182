import { gadaSaasApi } from "../../base-rtkq-api";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetItemProductUnitsRequest,
  GetItemProductUnitsResponse,
  GetSalesPerProductDetailsRequest,
  GetSalesPerProductDetailsResponse,
  GetSalesPerProductRequest,
  GetSalesPerProductResponse,
} from "./types";

const SalesProductApi = gadaSaasApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesPerProduct: builder.query<
      GetSalesPerProductResponse,
      GetSalesPerProductRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: {
          ...params.globalReportParams,
          ...params.listParams,
          storeId: params.storeId,
        },
        url: Endpoints.GET_SALES_PER_PRODUCT,
      }),
    }),
    getSalesPerProductDetails: builder.query<
      GetSalesPerProductDetailsResponse,
      GetSalesPerProductDetailsRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: {
          ...params.globalReportParams,
          storeId: params.storeId,
          uomId: params.uomId,
          productVariantId: params.productVariantId,
        },
        url: Endpoints.GET_SALES_PER_PRODUCT_DETAILS,
      }),
    }),
    downloadSalesPerProductReport: builder.mutation<
      string,
      GetSalesPerProductRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: {
          ...params.globalReportParams,
          ...params.listParams,
          storeId: params.storeId,
        },
        url: Endpoints.DOWNLOAD_SALES_PER_PRODUCT_REPORT,
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
    getItemProductUnits: builder.query<
      GetItemProductUnitsResponse,
      GetItemProductUnitsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_PRODUCT_UNIT,
        queryParams: { ...args },
      }),
    }),
  }),
});

export const {
  endpoints: SalesProductEndpoints,
  useGetSalesPerProductQuery,
  useLazyGetSalesPerProductDetailsQuery,
  useGetItemProductUnitsQuery,
  useDownloadSalesPerProductReportMutation,
} = SalesProductApi;

export default SalesProductApi;
