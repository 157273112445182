import {
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps as MuiTableProps,
  TableRowProps,
  TableSortLabelProps,
} from "@material-ui/core";
import * as React from "react";
import { TextProps } from "../Typography/types";

export interface TableProps {
  tableHeadingCells: TableHeadingCell[];
  renderTableData: () => React.ReactNode;
  renderTableDefaultRow?: () => React.ReactNode;
  containerClassName?: string;
  order?: Array<Order | undefined>;
  tableProps?: MuiTableProps;
  tableHeadProps?: TableHeadProps;
  tableRowProps?: TableRowProps;
  tableSortableCellProps?: TableCellProps;
  tableSortLabelProps?: TableSortLabelProps;
  tableSortableHeadingTextProps?: TextProps;
  tableCellProps?: TableCellProps;
  tableHeadingTextProps?: TextProps;
  tableBodyProps?: TableBodyProps;
  withPagination?: boolean;
  paginationProps?: PaginationProps;
}

export interface PaginationProps {
  total: number;
  page: number;
  perPage: number;
  sticky?: boolean;
  onPageChange: (newPage: number) => void;
  previousPageItemId?: string;
  nextPageItemId?: string;
  /** It will be generated as {pageItemPrefixId}_{pageNo} */
  pageItemPrefixId?: string;
}

export interface TableHeadingCell {
  id: string;
  content: React.ReactNode;
  isSortable?: boolean;
  sortIconPosition?: SortIconPosition;
  handleSort?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  tableCellProps?: TableCellProps;
  tableCellSortLabelProps?: TableSortLabelProps;
}

export enum SortIconPosition {
  LEFT = "left",
  RIGHT = "right",
}

export enum Order {
  Asc = "asc",
  Desc = "desc",
}

export interface Contacts {
  id: number;
  name: string;
  address: string;
  occupation: string;
}

export interface TableSortIconProps {
  sort?: Order | undefined;
}
