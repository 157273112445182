import { makeStyles } from "@material-ui/core";
import { AppTheme } from "../../theme";
import { pxToRem } from "../../utils";

interface iStylesProps {
  stickyFooter?: boolean;
}

export const useStyles = makeStyles<AppTheme, iStylesProps>(({ palette }) => ({
  container: {
    flex: 1,
    height: "100%",
    justifyContent: "space-between",
  },
  footer: ({ stickyFooter }) => ({
    height: pxToRem(56),
    width: "100%",
    justifyContent: "flex-end",
    backgroundColor: palette.interface.white,
    position: stickyFooter ? "sticky" : "static",
    bottom: 0,
    paddingTop: pxToRem(24),
  }),
  footerCell: {
    border: 0,
    paddingBottom: 0,
  },
}));
