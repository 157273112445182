import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { SortIconPosition } from ".";
import { Col, Row } from "../Layout";
import { Text } from "../Typography";
import { useStyles } from "./styles";
import TablePagination from "./TablePagination";
import TableSortIcon from "./TableSortIcon";
import { TableHeadingCell, TableProps } from "./types";

const Table: React.VFC<TableProps> = ({
  tableHeadingCells,
  renderTableData,
  order,
  containerClassName,
  tableProps,
  tableHeadProps,
  tableRowProps,
  tableSortableCellProps,
  tableSortLabelProps,
  tableSortableHeadingTextProps,
  tableCellProps,
  tableHeadingTextProps,
  tableBodyProps,
  withPagination = false,
  paginationProps,
  renderTableDefaultRow,
}) => {
  const classes = useStyles({ stickyFooter: paginationProps?.sticky });

  return (
    <Col className={clsx(classes.container, containerClassName)}>
      <MuiTable {...tableProps}>
        <TableHead {...tableHeadProps}>
          <TableRow {...tableRowProps}>
            {tableHeadingCells.map(
              (tableHeadingCell: TableHeadingCell, index: number) => {
                if (tableHeadingCell.isSortable) {
                  if (!tableHeadingCell.sortIconPosition) {
                    tableHeadingCell.sortIconPosition = SortIconPosition.RIGHT;
                  }
                  return (
                    <TableCell
                      id={tableHeadingCell.id}
                      key={tableHeadingCell.id}
                      {...tableSortableCellProps}
                      {...tableHeadingCell.tableCellProps}
                    >
                      <TableSortLabel
                        hideSortIcon
                        onClick={tableHeadingCell.handleSort}
                        {...tableSortLabelProps}
                        {...tableHeadingCell.tableCellSortLabelProps}
                      >
                        <Row flex={1}>
                          {tableHeadingCell.sortIconPosition ===
                            SortIconPosition.LEFT && (
                            <TableSortIcon
                              sort={order ? order[index] : undefined}
                            />
                          )}
                          <Text
                            variant="h5"
                            ink="neutralPrimary"
                            {...tableSortableHeadingTextProps}
                          >
                            {tableHeadingCell.content}
                          </Text>

                          {tableHeadingCell.sortIconPosition ===
                            SortIconPosition.RIGHT && (
                            <TableSortIcon
                              sort={order ? order[index] : undefined}
                            />
                          )}
                        </Row>
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={tableHeadingCell.id}
                      {...tableCellProps}
                      {...tableHeadingCell.tableCellProps}
                    >
                      <Text
                        variant="h5"
                        ink="neutralPrimary"
                        {...tableHeadingTextProps}
                      >
                        {tableHeadingCell.content}
                      </Text>
                    </TableCell>
                  );
                }
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody {...tableBodyProps}>
          {renderTableData()}
          {renderTableDefaultRow && renderTableDefaultRow()}
        </TableBody>
      </MuiTable>
      {withPagination && paginationProps ? (
        <Row className={classes.footer}>
          <TablePagination
            page={paginationProps.page}
            count={Math.ceil(paginationProps.total / paginationProps.perPage)}
            siblingCount={3}
            onPageChange={paginationProps.onPageChange}
            nextPageItemId={paginationProps.nextPageItemId}
            previousPageItemId={paginationProps.previousPageItemId}
            pageItemPrefixId={paginationProps.pageItemPrefixId}
          />
        </Row>
      ) : null}
    </Col>
  );
};

export default Table;
