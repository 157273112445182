import {
  addInventoryIntroTutorialSteps,
  hasTransactionTutorialSteps,
  inventoryBulkAddPageTutorialSteps,
  inventoryListPageTutorialSteps,
  posIntroTutorialSteps,
  posOrderCompleteTutorialSteps,
  posPembayaranTutorialSteps,
} from "@coach-mark/components";
import {
  coachMarkChains,
  setTutorialSteps,
  TutorialCode,
  useActiveStoreState,
  useGetAllTutorialsQuery,
  UserStoreRole,
} from "@gada-saas/web-core";
import { useTour } from "@reactour/tour";
import React from "react";
import { useDispatch } from "react-redux";
import { CoachMartListenerPropsInterface } from "./types";

const CoachMartListener = ({
  page,
  hasInventoryOrUoms,
  isLoading,
  itemRendered,
  uomType,
}: CoachMartListenerPropsInterface) => {
  const dispatch = useDispatch();

  const { setSteps, setCurrentStep, setIsOpen } = useTour();

  const activeStore = useActiveStoreState();

  const { data: tutorialsList, isLoading: tutorialsListIsLoading } =
    useGetAllTutorialsQuery();

  const isUserStoreRoleOwner = React.useMemo(
    () =>
      activeStore.storeRoleList[0]
        ? activeStore.storeRoleList[0].name === UserStoreRole.OWNER
        : false,
    [activeStore.storeRoleList]
  );

  // Handle showing app tutorial
  const showAppTutorial = React.useCallback(() => {
    if (
      isLoading ||
      tutorialsListIsLoading ||
      !tutorialsList?.data.progressList ||
      !isUserStoreRoleOwner
    ) {
      return;
    }

    const {
      ADD_INVENTORY_INTRO,
      POS_INTRO,
      ADD_INVENTORY_CHOOSE_SELLING_UNIT,
      POS_ORDER_COMPLETE,
      REPORTING_AFTER_ORDER_COMPLETE,
      ADD_INVENTORY_SEARCH_PRODUCT,
      INVENTORY_LIST_SEE_CURRENT_STOCK,
      POS_SELECT_PAYMENT_METHOD,
      ADD_INVENTORY_CHOOSE_STOCK_UNIT,
    } = tutorialsList?.data.progressList;

    if (page === "home") {
      // NO INVENTORY SEQUENCE
      if (!ADD_INVENTORY_INTRO) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.NO_INVENTORY,
            tutorialName: TutorialCode.ADD_INVENTORY_INTRO,
            totalTutorialSteps: 1,
          })
        );
        setSteps(addInventoryIntroTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      }
      // NO TRANSACTION SEQUENCE
      else if (ADD_INVENTORY_CHOOSE_SELLING_UNIT && !POS_INTRO) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_INVENTORY_POS,
            tutorialName: TutorialCode.POS_INTRO,
            totalTutorialSteps: 1,
          })
        );
        setSteps(posIntroTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      }
      // HAS TRANSACTION
      else if (POS_ORDER_COMPLETE && !REPORTING_AFTER_ORDER_COMPLETE) {
        // auto scroll navbar to bottom to avoid slow scroll down causing coach mark modal to glitch
        const el = document.getElementsByClassName(
          hasTransactionTutorialSteps[0].selector.toString().substring(1)
        )[0];
        el?.scrollIntoView(true);

        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_TRANSACTION,
            tutorialName: TutorialCode.REPORTING_AFTER_ORDER_COMPLETE,
            totalTutorialSteps: 3,
          })
        );
        setSteps(hasTransactionTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      }
    } else if (page === "pos") {
      // HAS TRANSACTION
      if (POS_ORDER_COMPLETE && !REPORTING_AFTER_ORDER_COMPLETE) {
        // auto scroll navbar to bottom to avoid slow scroll down causing coach mark modal to glitch
        const el = document.getElementsByClassName(
          hasTransactionTutorialSteps[0].selector.toString().substring(1)
        )[0];
        el?.scrollIntoView(true);

        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_TRANSACTION,
            tutorialName: TutorialCode.REPORTING_AFTER_ORDER_COMPLETE,
            totalTutorialSteps: 3,
          })
        );
        setSteps(hasTransactionTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      }
    } else if (page === "bulk_add") {
      // NO INVENTORY SEQUENCE
      if (!ADD_INVENTORY_SEARCH_PRODUCT) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.NO_INVENTORY,
            tutorialName: TutorialCode.ADD_INVENTORY_SEARCH_PRODUCT,
            totalTutorialSteps: 1,
          })
        );
        setSteps(inventoryBulkAddPageTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      } else if (
        uomType === "buying" &&
        itemRendered &&
        !ADD_INVENTORY_CHOOSE_STOCK_UNIT
      ) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.NO_INVENTORY,
            tutorialName: TutorialCode.ADD_INVENTORY_CHOOSE_STOCK_UNIT,
            totalTutorialSteps: 1,
          })
        );
        setSteps([inventoryBulkAddPageTutorialSteps[1]]);
        setCurrentStep(0);
        setIsOpen(true);
      } else if (
        uomType === "selling" &&
        itemRendered &&
        hasInventoryOrUoms &&
        !ADD_INVENTORY_CHOOSE_SELLING_UNIT
      ) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.NO_INVENTORY,
            tutorialName: TutorialCode.ADD_INVENTORY_CHOOSE_SELLING_UNIT,
            totalTutorialSteps: 1,
          })
        );
        setSteps([inventoryBulkAddPageTutorialSteps[2]]);
        setCurrentStep(0);
        setIsOpen(true);
      }
    } else if (page === "inventory_list") {
      // NO TRANSACTION SEQUENCE
      if (
        hasInventoryOrUoms &&
        ADD_INVENTORY_CHOOSE_SELLING_UNIT &&
        !INVENTORY_LIST_SEE_CURRENT_STOCK
      ) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_INVENTORY_LIST,
            tutorialName: TutorialCode.INVENTORY_LIST_SEE_CURRENT_STOCK,
            totalTutorialSteps: 1,
          })
        );
        setSteps([inventoryListPageTutorialSteps[0]]);
        setCurrentStep(0);
        setIsOpen(true);
      }
    } else if (page === "pembayaran" && ADD_INVENTORY_CHOOSE_SELLING_UNIT) {
      // NO TRANSACTION SEQUENCE
      if (!POS_SELECT_PAYMENT_METHOD) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_INVENTORY_POS,
            tutorialName: TutorialCode.POS_SELECT_PAYMENT_METHOD,
            totalTutorialSteps: 1,
          })
        );
        setSteps(posPembayaranTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      } else if (!POS_ORDER_COMPLETE && itemRendered) {
        dispatch(
          setTutorialSteps({
            chainName: coachMarkChains.HAS_INVENTORY_POS,
            tutorialName: TutorialCode.POS_ORDER_COMPLETE,
            totalTutorialSteps: 1,
          })
        );
        setSteps(posOrderCompleteTutorialSteps);
        setCurrentStep(0);
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasInventoryOrUoms,
    isLoading,
    tutorialsList?.data.progressList,
    tutorialsListIsLoading,
    isUserStoreRoleOwner,
  ]);

  React.useEffect(() => {
    showAppTutorial();
  }, [showAppTutorial]);

  return null;
};

export default CoachMartListener;
