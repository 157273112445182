import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppTheme } from "@gada-saas/web-ui/theme";
import { pxToRem } from "@gada-saas/web-ui/utils";

export const useStyles = makeStyles<AppTheme>((theme) => ({
  productVariantImg: {
    border: "1px solid " + theme.palette.interface.neutral.light,
    width: pxToRem(56),
    height: pxToRem(56),
    maxWidth: pxToRem(56),
    maxHeight: pxToRem(56),
    overflow: "hidden",
  },
  card: { padding: `${pxToRem(4)} 0` },
  tableContainer: {
    maxHeight: pxToRem(408),
    overflowY: "auto",
  },
  tableHeadingCell: {
    padding: `${pxToRem(24)} ${pxToRem(16)} ${pxToRem(16)}`,
  },
  td: {
    paddingTop: pxToRem(3),
    paddingBottom: pxToRem(3),
  },
  salesPerMonthCell: {
    textAlign: "right",
  },
  unitCell: {
    textAlign: "left",
  },
  julanCell: {
    textAlign: "left",
  },
  icon: {
    marginLeft: pxToRem(10),
    marginTop: pxToRem(3),
    cursor: "pointer",
    color: theme.palette.interface.neutral.secondary,
    fontSize: pxToRem(14),
  },
}));
