import { Container as MuiContainer, Chip as MuiChip } from "@material-ui/core";
import { AppTheme } from "@gada-saas/web-ui";
import { createStyles, makeStyles, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    leftGrid: {
      padding: theme.spacing(1),
    },
    rightGrid: {
      padding: theme.spacing(1),
    },
    pointer: {
      cursor: "pointer",
    },
    welcomeText: {
      fontFamily: "Inter-Regular",
      fontWeight: 400,
    },
    userName: {
      fontFamily: "Inter-Bold",
      fontWeight: 700,
    },
  })
);

export const Container = withStyles({
  root: {
    backgroundColor: "inherit",
  },
})(MuiContainer);

export const Chip = withStyles(({ palette }: AppTheme) => ({
  root: {
    backgroundColor: palette.interface.red.light,
    color: palette.interface.red.primary,
    boxShadow: "none",
  },
}))(MuiChip);
