import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  container: {
    padding: `${pxToRem(12)} ${pxToRem(16)}`,
    backgroundColor: theme.palette.interface.red.light,
    border: `${pxToRem(1)} solid ${theme.palette.interface.red.dark}`,
    borderRadius: pxToRem(8),
    marginBottom: pxToRem(48),
  },
  flexStart: {
    alignSelf: "flex-start",
  },
  middleContainer: {
    flex: 1,
    marginLeft: pxToRem(12),
    marginRight: pxToRem(12),
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  linkText: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconButton: {
    padding: 0,
  },
  closeIcon: {
    width: pxToRem(16),
  },
}));

export default useStyles;
