import { iListApiResponse } from "../common/models";

export interface NotificationBanner {
  id: string;
  title: string;
  description: string;
  image?: string;
  actionUrl?: string;
  actionType: string;
  actionText: string;
  canBeClosed: boolean;
  viewedBy: string;
}

export type GetNotificationBannerResponse = iListApiResponse<
  NotificationBanner[]
>;

export enum NotificationBannerType {
  WEB = "WEB",
  APP = "APP",
}

export interface GetNotificationBannerRequest {
  type: NotificationBannerType;
}
