import { LoudSpeaker } from "@common/icons";
import {
  NotificationBannerType,
  updatedClosedBanners,
  useClosedBannersState,
  useGetNotificationBannerQuery,
} from "@gada-saas/web-core";
import { Col, Row, Text } from "@gada-saas/web-ui";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import * as React from "react";
import { useDispatch } from "react-redux";
import useStyles from "./styles";

const NotificationBanner: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { banners } = useClosedBannersState();

  const { isLoading, data } = useGetNotificationBannerQuery({
    type: NotificationBannerType.WEB,
  });

  const handleClick = () => {
    window.open(data?.data[0].actionUrl, "_blank");
  };

  const handleClose = React.useCallback(
    (id: string) => {
      dispatch(updatedClosedBanners(id));
    },
    [dispatch]
  );

  if (isLoading || !data?.data[0]?.id || banners.includes(data.data[0].id)) {
    return null;
  }

  return (
    <Row className={classes.container}>
      <Col className={classes.flexStart}>
        <LoudSpeaker />
      </Col>

      <Col className={classes.middleContainer} spacing={4}>
        <Text variant="h5">{data.data[0].title}</Text>

        <Text variant="captionLight" className={classes.description}>
          {data.data[0].description}
        </Text>

        {data.data[0].actionText && (
          <Text
            variant="caption"
            ink="tealPrimary"
            className={classes.linkText}
            onClick={handleClick}
          >
            {data.data[0].actionText}
          </Text>
        )}
      </Col>

      {data.data[0].canBeClosed && (
        <IconButton
          className={clsx(classes.iconButton, classes.flexStart)}
          onClick={() => handleClose(data.data[0].id)}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
    </Row>
  );
};

export default NotificationBanner;
