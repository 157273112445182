import { pxToRem } from "../../utils";
import { createStyles, withStyles, Chip as MuiChip } from "@material-ui/core";
import { ChipProps } from "./types";

const Chip = withStyles<"root", never, ChipProps>(({ palette }) => {
  return createStyles({
    root: {
      padding: `${pxToRem(4)} ${pxToRem(8)}`,
      margin: (props) => (props.margin ? props.margin : pxToRem(8)),
      boxShadow: "none",
      borderRadius: (props) => (props.borderRadius ? props.borderRadius : pxToRem(16)),
      borderWidth: 0,
      color: (props) =>
        props.type === "red" 
          ? palette.interface.red.primary 
          : props.color,
      backgroundColor: (props) =>
        props.type === "blue"
          ? palette.interface.blue.lighter
          : props.type === "orangeLight"
            ? palette.interface.orange.light
            : props.type === "green"
              ? palette.interface.teal.lighter
              : props.type === "redLight" || props.type === "red"
                ? palette.interface.red.light
                : palette.interface.neutral.dark,
    },
  });
})(MuiChip);

export default Chip;
