import { makeStyles } from "@material-ui/core";
import { AppTheme, pxToRem } from "@gada-saas/web-ui";

const useStyles = makeStyles((theme: AppTheme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  headingContainer: {
    padding: `${pxToRem(16)} ${pxToRem(24)}`,
  },
  rightIcon: {
    color: theme.palette.interface.teal.primary,
  },
  description1: {
    marginBottom: pxToRem(24),
  },
  bold: {
    fontWeight: 700,
  },
  description2: {
    marginBottom: pxToRem(40),
  },
  span: {
    fontWeight: 700,
    color: theme.palette.interface.teal.primary,
  },
}));

export default useStyles;
